import React, { useState } from 'react';
import domtoimage from 'dom-to-image';
import styled from 'styled-components';
import { inject } from 'mobx-react';
import { displayError } from '@aws-ee/base-ui/dist/helpers/notification';
import moment from 'moment';
import certificatePathBackgroundImg from '../../../../images/certificate-path-background.svg';
import certificateBackgroundImg from '../../../../images/certificate-background.svg';
import certificateBadgeImg from '../../../../images/certificate-badge.svg';
import certificateSignatureImg from '../../../../images/certificate-signature.svg';
import stanfordGeneticsLogoImg from '../../../../images/stanford-genetics-logo.svg';
import ProgressEnum from '../../../../models/learning/ProgressEnum';

const Container = styled.div`
  height: 450px;
  margin-top: 140px;
  display: flex;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 410px;
`;

const CertificateExam = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.08em;
  color: #d1660f;
  margin: 0px 0px 8px 0px;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  color: #2e2d29;
  margin: 0px 0px 32px 0px;
`;

const Description = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #2e2d29;
  margin: 0px 0px 32px 0px;
`;

const StartExamButton = styled.button`
  width: fit-content;
  padding: 12px 16px;
  background: #2e2d29;
  border-radius: 4px;
  font-family: 'IBM Plex Mono';
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #fafafa;
  border: none;
  cursor: pointer;

  :disabled {
    background: #969dab;
    pointer-events: none;
  }
`;

const Subtitle = styled.p`
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #969dab;
  margin: 16px 0px 0px 0px;
`;

const AccessCertificateButton = styled.button`
  width: fit-content;
  border: none;
  padding: 12px 16px;
  height: 46px;
  background: #f9a44a;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;

const CertificateContainer = styled.div`
  background-image: url(${certificatePathBackgroundImg});
  height: 780px;
  width: 780px;
  margin-left: 60px;
  background-repeat: no-repeat;
  background-clip: content-box;
  display: flex;
  justify-content: center;
`;

const Certificate = styled.div`
  width: 566px;
  height: 400px;
  border-top: 10px solid #8c1515;
  border-bottom: 10px solid #8c1515;
  border-radius: 8px;
  margin-top: 20px;
  background-image: url(${certificateBackgroundImg});
  background-color: #ffffff;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const CertificateHeader = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr repeat(3, auto) 1fr;
  grid-column-gap: 5px;
  justify-items: center;
`;

const CertificateLogo = styled.img`
  width: 216px;
  height: 27px;
  grid-column-start: 4;
`;

const CertificateBadge = styled.img`
  width: 80.13px;
  height: 80.13px;
  margin: 10px 20px 0px auto;
`;

const CertificateBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0px 123px;
  text-align: center;
`;

const CertificateTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: #2e2d29;
  opacity: 0.9;
  padding: 0px 25px;
  margin: 3px 0px 0px 0px !important;
  text-align: center;
`;

const ProgramDuration = styled.p`
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 8px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #2f2424;
  opacity: 0.9;
  margin: 8px 0px 0px 0px;
`;

const CertificationDate = styled.p`
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-size: 8px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #53565a;
  opacity: 0.5;
  margin: 8px 0px 0px 0px;
`;

const UserName = styled.p`
  font-family: 'Source Sans Pro';
  font-weight: 900;
  font-size: 32px;
  line-height: 126%;
  text-align: center;
  color: #2f2424;
  margin: 16px 0px 0px 0px;
`;

const IssuerDirectorSignature = styled.img`
  width: 100%;
  height: 40px;
  margin: 40px 0px 0px 0px;
  border-bottom: 0.500832px solid rgba(83, 86, 90, 0.25);
`;

const IssuerDirectorWithDegree = styled.p`
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 8px;
  line-height: 135%;
  color: #53565a;
  opacity: 0.5;
  margin: 12px 0px 6px 0px;
`;

const IssuerDirectorTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-size: 8px;
  line-height: 135%;
  color: #53565a;
  opacity: 0.5;
  margin: 0px 0px 2px 0px;
  width: 100%;
`;

const CertificatePath = ({ learningId, path, disabled, learningStore, userStore }) => {
  const [buttonInProgress, setButtonInProgress] = useState(false);

  const module = path.modules[0];
  const currentUser = userStore.user;

  const capitalizeFirstLetter = value => value.charAt(0).toUpperCase() + value.slice(1);

  const handleStart = async () => {
    setButtonInProgress(true);
    try {
      if (module.progress === ProgressEnum.NotStarted)
        await learningStore.startUserLearningModule(learningId, path.id, module.id);
    } catch (error) {
      displayError(error);
    }
    setButtonInProgress(false);
    window.open(`/learn/${learningId}/path/${path.id}/${module.id}/quiz`, '_blank').focus();
  };

  const handleDownloadCertificate = async () => {
    const element = document.getElementById('certificate');
    const scale = 2;
    const style = {
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      width: `${element.offsetWidth}px`,
      height: `${element.offsetHeight}px`,
    };
    const param = {
      width: element.offsetWidth * scale,
      height: (element.offsetHeight + 100) * scale,
      quality: 1,
      style,
    };

    domtoimage.toPng(element, param).then(dataUrl => {
      const link = document.createElement('a');
      link.download = module.name;
      link.href = dataUrl;
      link.click();
    });
  };

  return (
    <Container>
      <Info>

      {module.progress === ProgressEnum.Completed ? (
        <>
        <CertificateExam>CONGRATULATIONS</CertificateExam>
        <Title>
          You earned the certificate! 🎉
        </Title>
        <Description>
          We are happy for your accomplishment! We hope the 
          time you spent on our program helps pave the journey 
          for your future achievements.

          <br />
          <br />
          At last, we have a few kind asks from you:
          <ol>
            <li>Please help our team learn which part of the program is most useful to you via this <a href="https://stanfordmedicine.qualtrics.com/jfe/form/SV_6yReVVyi76GwKVw" target="_blank" rel="noopener noreferrer" style={{ color: 'orange', textDecoration: 'underline' }}>5-min survey</a>.</li>
            <li>If you believe your friends and colleagues on LinkedIn can benefit from this program, please help us spread the words by sharing your certificate on LinkedIn, and tagging <a href="https://www.linkedin.com/company/deep-data-stanford/" target="_blank" rel="noopener noreferrer" style={{ color: 'orange', textDecoration: 'underline' }}>Stanford Deep Data Research Center</a>.</li>
          </ol>          
        </Description>
        </>
        ) : (
        <>
          <CertificateExam>CERTIFICATE EXAM</CertificateExam>
          <Title>
            Get certified by the {module.issuer.department} at {module.issuer.name}
          </Title>
          <Description>
            The certificate exam will cover all the topics in each milestone. You are going to receive the certificate 
            after you respond to more than {module.minValidPercentage}% of the questions correctly.
            <br />
            <br />
            You can take the exam {module.maxNumberOfTrials} times. Make sure to spend ample time reviewing the content,
            and practice exercises before each attempt.
          </Description>
          </>
        )}
        {module.progress === ProgressEnum.Completed ? (
          <AccessCertificateButton type="button" onClick={handleDownloadCertificate}>
            {' '}
            <span role="img" aria-label="rocket">
              ‍{' '}
            </span>
            🎓 Access Certificate
          </AccessCertificateButton>
        ) : (
          <StartExamButton
            type="button"
            disabled={disabled || buttonInProgress || module.numberOfTrials >= module.maxNumberOfTrials}
            onClick={handleStart}
          >
            <span role="img" aria-label="rocket">
              🚀{' '}
            </span>
            Start Exam
          </StartExamButton>
        )}
        {module.progress !== ProgressEnum.Completed && module.numberOfTrials < module.maxNumberOfTrials ? (
          <Subtitle>Carefully study before you make any of the {module.maxNumberOfTrials} attempts.</Subtitle>
        ) : (
          <></>
        )}
      </Info>
      <CertificateContainer>
        <Certificate id="certificate">
          <CertificateHeader>
            <CertificateLogo src={stanfordGeneticsLogoImg} alt="stanford-genetic-logo" />
            <CertificateBadge src={certificateBadgeImg} alt="certificate-badge" />
          </CertificateHeader>
          <CertificateBody>
            <CertificateTitle>{module.name}</CertificateTitle>
            <ProgramDuration>Program Duration: {module.programDuration / 60 / 60} hours</ProgramDuration>
            {module.progress === ProgressEnum.Completed ? (
              <>
                <CertificationDate>
                  Certificate of Achievement granted on {moment(module.updatedAt).format('MMMM DD, yyyy')} to
                </CertificationDate>
                <UserName>{`${capitalizeFirstLetter(currentUser.firstName)} ${capitalizeFirstLetter(
                  currentUser.lastName,
                )}`}</UserName>
                <IssuerDirectorSignature src={certificateSignatureImg} alt={module.issuer.director.name} />
                <IssuerDirectorWithDegree>
                  <span style={{ fontWeight: 'bold' }}>{module.issuer.director.name}</span>, {module.issuer.director.degree}
                </IssuerDirectorWithDegree>
                
                {module.issuer.director.titles.map(t => (
                  <IssuerDirectorTitle>{t}</IssuerDirectorTitle>
                ))}
              </>
            ) : (
              <></>
            )}
          </CertificateBody>
        </Certificate>
      </CertificateContainer>
    </Container>
  );
};

export default inject('userStore', 'learningStore')(CertificatePath);
